import request from '@/utils/request'


// 获取推送策略
export function getAllStrategy(listquery = {}) {
    return request.post('/strategy/getAll', listquery)
}

// 获取推送策略列表
export function getStrategyList(data) {
    return request({
        url: '/strategy/list',
        method: 'get',
        params: data,
    })
}

// 保存推送策略
export function saveStrategy(data) {
    return request.post('/strategy/save', data)
}

export function saveStrategyPeriod(data) {
    return request.post('/strategy/reset/period', data)
}
