import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/login/info',
    method: 'get',
    params: { token }
  })
}
export function logout() {
  return request.post('/login/logout')
}

export function getAdminById(id) {
  return request.post('/admin-user/get', {id})
}

// 编辑
export function createOrUpdateInfo(form) {
  return request.post('/admin-user/createorupdate', form)
}

// 获取员工信息
export function queryAdminInfo(listquery) {
  return request.post('/admin-user/getUser', listquery)
}

export function changeStatus(id, status) {
  return request.post('/admin-user/changeStatus', { id, status })
}

export function getStatus() {
  return {
    '0': '正常',
    '99': '禁用'
  }
}

export function getRole() {
  return {
    'admin': '管理员', 
    'editor': '普通用户',
    'other': '其他用户'
  }
}
