import request from '@/utils/request'

export function getMenu() {
  return request({
    url: '/dashboard/menu',
    method: 'post'
  })
}


export function info() {
  return request.get('/dashboard/info')
}

export function boom() {
  return request.post('/dashboard/boom', { yes: 'yes'})
}

export function createOrUpdateImg(img) {
  return request.post('/dashboard/createOrUpdateImg', img)
}

export function getSwipers() {
  return request.post('/dashboard/getSwipers')
}

export function changeStatus(id, status) {
  return request.post('/dashboard/changeStatus', { id, status })
}

export function deleteImage(id) {
  return request.post('/dashboard/deleteImage', { id })
}


export function getUploadImg(type) {
  return process.env.VUE_APP_BASE_API + '/upload_img?type=' + type
}

export function queryEmaillog(listquery) {
  return request.post('/dashboard/queryEmail', listquery)
}