<template>
    <div :class="{ 'has-logo': showLogo }">
        <logo v-if="showLogo" :collapse="isCollapse" />
        <el-scrollbar wrap-class="scrollbar-wrapper">
            <el-menu 
                :default-active="activeMenu" 
                :collapse="isCollapse" 
                :background-color="variables.menuBg"
                :text-color="variables.menuText" 
                :unique-opened="false" 
                :active-text-color="variables.menuActiveText"
                :collapse-transition="false" 
                mode="vertical">
                <sidebar-item v-for="route in menu" :key="route.url" :item="route" :base-path="route.url" />
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.module.scss'

import { getMenu } from '@/api/dashboard'
import { getAllStrategy } from '@/api/strategy'
export default {
    name: 'sidemenu',
    components: { SidebarItem, Logo },
    computed: {
        ...mapGetters([
            'permission_routes',
            'sidebar'
        ]),
        activeMenu() {
            const route = this.$route
            const { meta, path } = route
            // if set path, the sidebar will highlight the path you set
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            return path
        },
        showLogo() {
            return this.$store.state.settings.sidebarLogo
        },
        variables() {
            return variables
        },
        isCollapse() {
            return !this.sidebar.opened
        }
    },
    data() {
        return {
            menu: []
        }
    },
    created() {
        this.fetch()
    },
    methods: {
        fetch() {
            getMenu().then(response => {
                if (response.code === 20000) {
                    // this.menu = response.data;
                    this.menu = response.data.map(item => ({
                        ...item,
                        icon: 'guide'
                    }))
                }
            }).catch(error => {
                console.log(error)
            })
            this.$store.dispatch('user/setStrategyList');
            // getAllStrategy().then(res => {
            //     if (res.code === 20000) {
            //         this.$store.commit()
            //     }
            // })

        }
    }
}
</script>
