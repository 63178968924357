import { createApp } from 'vue'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import './styles/element-variables.module.scss'

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import installIcon from './icons' // icon
import './permission' // permission control
// import installErrorLog from'./utils/error-log' // error log


const app = createApp(App)

installIcon(app)
// installErrorLog(app)


app.use(ElementPlus, {
  size: 'default', // set element-ui default size
})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.config.productionTip = false

app.use(router).use(store).mount('#app')
